<template>
  <div class="container">
    <header>
      <div class="header-title">
        Code Cage
      </div>
      <nav>
        <input type="checkbox" id="nav-check">
        <ul class="nav-links">
          <li><a href="#">Start</a></li>
          <li><a href="#section-offer">Offer</a></li>
          <!-- <li><a href="#">About</a></li> -->
          <li><a href="#section-tech-stack">Technologies</a></li>
          <li><a class="cta" href="#section-contact">Contact</a></li>
        </ul>
        <div class="mobile-btn">
          <label for="nav-check">
            <span class="hb-1"></span>
            <span class="hb-2"></span>
            <span class="hb-3"></span>
          </label>
        </div>
      </nav>
    </header>
    <section class="main">
      <div class="main__text">
        <p>Tailor-made software</p>
        <p>developed using</p>
        <p>modern web technologies</p>
      </div>
    </section>
    <section class="offer" id="section-offer">
      <div class="offer__header"><p>Looking for a custom web software solution ?</p></div>
      <div class="offer__body">
        <p>Sometimes you might not need one as there may already be other viable options.</p>
        <p>When I see that this is the case I will respond with advice for you.</p>
        <p>If you are not satisfied with already existing solutions, we will have a ground for further discussing your particular case and <strong>how I can help by delivering what you actually need.</strong></p>
      </div>
      <div class="offer__cta-btn">Send an inquiry</div>
    </section>
    <section class="tech-stack" id="section-tech-stack">
      <div class="tech-stack__title">The technology stack <br />that I use on a regular basis <br />to deliver cost-effective <br />solutions</div>
      <div class="tech-stack__icons">
        <div class="line">
          <img src="@/assets/img/vuejs.svg" alt="">
          <img src="@/assets/img/dotnet.svg" alt="">
        </div>
        <div class="line">
          <img class="sql-server" src="@/assets/img/sql-server-icon-png.svg" alt="">
          <img class="mariadb" src="@/assets/img/mariadb.svg" alt="">
          <img src="@/assets/img/graphql.svg" alt="">
        </div>
        <div class="line">
          <img src="@/assets/img/sass.svg" alt="">
          <img src="@/assets/img/signalr.svg" alt="">
        </div>
        <div class="line">
          <img src="@/assets/img/docker.svg" alt="">
          <img src="@/assets/img/azure.svg" alt="">
        </div>
      </div>
    </section>
    <section class="contact" id="section-contact">
      <div class="contact__form">
        <div class="form">
          <div class="form__header">
            Below you can contact me about the problem you think you would need a solution for.
          </div>
          <div class="form__inputs">
            <div class="input input__email">
              <input type="email" placeholder="your email address" />
            </div>
            <div class="input input__inquiry">
              <textarea row=5 placeholder="the description of what you need"/>
            </div>
          </div>
        </div>
        <div class="send-btn">Send</div>
      </div>
    </section>
    <footer>
      <div class="attribution">
        <a href="http://www.freepik.com">Background designed by starline / Freepik</a>
      </div>
      <div class="copyrights">
        <span>Made by Code Cage</span>
      </div>
    </footer>
  </div>
</template>
